import React, { useEffect, useState } from 'react';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Button,
    TextField,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import DashboardCard from '../../components/shared/DashboardCard';
import { deleteAreaItems, editArea, getZoneByHQ, getloadArea, getloadAreaByRegion, getloadRegionByZone, getloadZone, saveArea, saveHQ } from 'src/service/auth';
import { Edit, Delete } from '@mui/icons-material';
import styled from '@emotion/styled';
import { API_URL } from 'src/service/routes';

const AreaList = () => {

    const StyledFormControl = styled(FormControl)({
        margin: "8px",
        minWidth: "120px",
    });
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"))

    const [selZone,setSelZone] = useState();
    const [selRegion,setSelRegion] = useState();

    const [zoneList, setZoneList] = useState([]);
    const [reagionList, setReagionList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [reagList, setReagList] = useState([]);

    const [name, setName] = useState("");
    const [zone, setZone] = useState();
    const [region, setRegion] = useState("");
    const [id, setId] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = async () => {
        if(name==null){
            alert("Please Enter Area")
            return
        }
        if(region==null){
            alert("Please Enter Region")
            return 
        }
        if(id==""){
            await saveArea(name,region);
        }else{
            await editArea(name,region,id);
        }
       
        loadData();
        setOpen(false)
    }
    const handleClose = () =>{
        setName("");
        setId("")
        setOpen(false)
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleRegionChange = (event) => {
        setRegion(event.target.value);
    };
    
    useEffect(() => {
        loadData();
        
    }, []);

    useEffect(() => {
        loadReagion(selZone)
        loadArea()
    }, [selZone]);

    useEffect(() => {
        loadAreabyReagionId(selRegion)
    }, [selRegion]);
    const loadAreabyReagionId = async(selRegion)=>{
        if(selRegion){
            const campSummary = await getloadAreaByRegion(selRegion);
            setAreaList(campSummary);
        }
    }
    const loadData = ()=>{
        if(userInfo.emp_role_id == 5){ 
            loadZone();
            loadArea()
        }else if(userInfo.emp_role_id == 4){ 
            loadReagion(userInfo.zoneId);
            loadAreabyZone(userInfo.zoneId)
        }else { 
            setRegion(userInfo.regionId)
            loadAreabyReagion(userInfo.regionId)
        }
    }
    const loadAreabyZone = async()=>{
        const campSummary = await getloadArea();
        setAreaList(campSummary);
    }
    const loadAreabyReagion = async()=>{
        const campSummary = await getloadArea();
        setAreaList(campSummary);
    }
    const loadReagion = async(zoneId)=>{
        if(zoneId){
            const campSummary = await getloadRegionByZone(zoneId);
            setReagionList(campSummary);
        }
    }
    const loadZone = async()=>{
        const campSummary = await getloadZone();
        setZoneList(campSummary);
    }
    const loadArea = async()=>{
        const campSummary = await getloadArea(selZone,selRegion);
        setAreaList(campSummary);
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const openEditBox = (item)=>{
        setId(item.area_id)
        setName(item.area_name)
        setRegion(item.regionId)

        setOpen(true);
    }
    const deleteArea = async(item)=>{
        let text = "Are you sure delete Area";
        if (window.confirm(text) == true) {
            await deleteAreaItems(item.area_id);
            loadData();
        }
    }
    const handleGenerateExcel = ()=>{
        let zone = "";
        if(selZone){ zone=`${zone}&zone=${selZone}`;}
        else if(userInfo.emp_role_id!=5){ zone=`${zone}&zone=${userInfo.zoneId}`; }
        if(selRegion){ zone=`${zone}&region=${selRegion}`;}
        else if(userInfo.emp_role_id>4){ zone=`${zone}&region=${userInfo.regionId}`; }
        window.open(`${API_URL}camp/generate_excel_by_area?role_id=${userInfo.emp_role_id}${zone}`, '_blank')
      }
    return (

        <DashboardCard title="Area">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                 <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        userInfo.emp_role_id == 5 &&  <>
                            
                            <StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                                <InputLabel>Zone</InputLabel>
                                <Select value={selZone} onChange={(event)=>setSelZone(event.target.value)} label="Gender">
                                    {
                                        zoneList && zoneList.map(e=>(
                                                <MenuItem value={e.zoneId}>{e.zoneName}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </StyledFormControl>
                        </>
                    }
                    {
                        userInfo.emp_role_id > 3 &&  <>
                            
                            <StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                                <InputLabel>Region</InputLabel>
                                <Select value={selRegion} onChange={(event)=>setSelRegion(event.target.value)} label="Gender">
                                    {
                                        reagionList && reagionList.map(e=>(
                                                <MenuItem value={e.region_id}>{e.region_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </StyledFormControl>
                        </>
                    }
                    <div style={{display: 'flex',alignItems:'center'}}>
                        <Button style={{width:150,height:40,marginRight:10}} variant="contained" color="primary" onClick={()=>setOpen(true)}>
                        Add Area
                        </Button>
                        <Button style={{width:150,height:40}} variant="contained" color="primary"  onClick={handleGenerateExcel}>
                            Generate Excel
                        </Button>
                    </div>
               </Box>
       
                <Table
                    aria-label="simple table"
                    sx={{
                        whiteSpace: "nowrap",
                        mt: 2
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                  Name
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                Region
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                Zone
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {areaList!=null && areaList.map((product) => (
                            <TableRow key={product.hq_id}>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {product.area_name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {product.region_name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {product.zoneName}
                                    </Typography>
                                </TableCell>
                                {
                                    userInfo.emp_role_id > 2 &&
                                <TableCell>
                                    <IconButton aria-label="edit" onClick={()=>openEditBox(product)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={()=>deleteArea(product)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <TextField
                            label="Area Name"
                            variant="outlined"
                            value={name}
                            onChange={handleNameChange}
                            style={{ margin: "8px",width:"100%" }}
                        />
                        {
                            zoneList.length > 0  && <>
                            <StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                                <InputLabel>Zone</InputLabel>
                                <Select value={zone} onChange={(event)=>{
                                    setZone(event.target.value)
                                    loadReagion(event.target.value)
                                }} label="Gender">
                                    {
                                        zoneList && zoneList.map(e=>(
                                                <MenuItem value={e.zoneId}>{e.zoneName}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </StyledFormControl>
                            </>
                        }
                        {
                            reagionList.length > 0  && <StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                                <InputLabel>Region</InputLabel>
                                <Select value={region} onChange={handleRegionChange} label="Gender">
                                    {
                                        reagionList && reagionList.map(e=>(
                                                <MenuItem value={e.region_id}>{e.region_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </StyledFormControl>
                        }

                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" style={{marginRight:10}} color="primary" onClick={handleOpen}>
                                {id==""?'Save':'Edit'}
                            </Button>
                            <Button variant="contained" color="secondary" onClick={()=>setOpen(false)}>
                                {"Close"} 
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </DashboardCard>
    );
};

export default AreaList;
